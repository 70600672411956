import styled from 'styled-components';

export const ContainerWrapper = styled.button`
    position: absolute;
    width: 100%;
    width: calc(100% - 28px);
    height: 86px;
    background: #FFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
    transition: all 0.75s cubic-bezier(0.5,0,0,1);

    bottom: 20px;
    left: 14px;
    outline: none;

    position: ${props => props.nofixed && 'relative'};
    bottom: ${props => props.nofixed && 'auto'};
    left: ${props => props.nofixed && 'auto'};
    margin-top: ${props => props.nofixed && '40px'};

    &:active {
        background-color: #0064BA;
        span, strong {
            color: #FFF !important;
            transition: all 0.45s cubic-bezier(0.5,0,0,1);
            transition-delay: 0.3s;
        }
    }
    @media (min-width: 601px) {
        display: none;
    }
`;

export const Img = styled.img`
    position: absolute;
    top: -20px;
    right: 34px;
    width: 40px;
    height: 40px;
    z-index: 10;
`;

export const CenteredText = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    font-size: 18px;
    text-align: center;

    color: #0064BA;
    font-family: 'Work Sans';
    strong { font-weight: 700; }
`;

export default {
    ContainerWrapper,
    Img,
    CenteredText
}
