import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    AccordiumContainer,
    H4,
    AccordiumItem,
} from './elements';
import P from '../p';

const PAnimated = styled(P)`
    transition: all 0.4s ease-in-out;
`;

const SingleAccordium = ({
    title,
    text
}) => {
    const [active, setActive] = useState(false);

    const handleClick = () => {
        setActive(!active);
    };

    return <AccordiumItem>
        <H4 onClick={() => handleClick()}>{title}</H4>
        <PAnimated style={{
            maxHeight: active ? 600 : 1,
            marginTop: active ? 15 : 0,
            overflow: 'hidden'
        }}>
            <pre
                dangerouslySetInnerHTML={{__html: text}}
                style={{
                    whiteSpace: 'pre-line'
                }}
            />
        </PAnimated>
    </AccordiumItem>
}

const Accordium = ({ items, language = 'en', }) => {
    
    return <AccordiumContainer>
        {
            items.map(element => {
                if ( language === 'kh' ) {
                    return (
                        <SingleAccordium
                            title={element.title_kh}
                            text={element.paragraph_kh}
                        />
                    )
                }
                return (
                    <SingleAccordium
                        title={element.title}
                        text={element.paragraph}
                    />
                )
            })
        }
    </AccordiumContainer>
};

export default Accordium;
