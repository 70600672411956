import React, { useState, useContext } from 'react';
import { ContainerWrapper, Img, CenteredText } from './elements';
import WindowExists from '../../utils/windowExists';

import FormContext from '../../global/formContext';

import SVG from '../requestConsultationButton/user.svg';

const EVENT_NAME = 'ui-form-toggle';

const RequestButton = ({
    normalText = 'Request Consultation',
    boldText = 'Now',
    nofixed = false,
}) => {
    const formStatus = useContext(FormContext);
    const setForm = formStatus.setFormContact;
    const [ clicked, setClicked ] = useState(false);


    const handleClick = () => {
        if ( clicked ) {
            return false;
        }
        setClicked(true);
        setTimeout(() => {
            setClicked(false);
            setForm({
                active: true,
                title: 'Contact Us',
                subTitle: 'Request Consultation',
            });
        }, 500);
    }
    
    return (
        <ContainerWrapper
            nofixed={nofixed}
            onClick={handleClick}
        >
            <Img alt="User" src={SVG} />
            <CenteredText>
                <span dangerouslySetInnerHTML={{
                    __html: normalText,
                }} />
                &nbsp;
                <strong>{boldText}</strong>
            </CenteredText>
        </ContainerWrapper>
    )
}

export default RequestButton;
