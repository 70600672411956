import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link as BaseLink, navigate } from 'gatsby';
import RequestConsultationMobileButton from '../../components/requestconsultationmobileButton';

import MaskProduct from '../../assets/images/maskproduct.png';
const Page = styled.div`
  position: relative;
  min-height: 100vh;
  background: #FFF;
  padding-top: 241px;
  max-width: 1241px;
  margin: 0 auto;
  padding: 0 135px;
  padding-top: 241px;
  @media (max-width: 1200px) {
    padding: 0 57px;
    padding-top: 241px;
    min-height: auto;
  }

  width: 100%;
  max-width: 1620px;
  @media (max-width: 1600px) {
    max-width: 1241px;
    padding: 0 104px;
    padding-top: 241px;
  }
  @media (max-width: 1200px) {
      padding: 0 57px;
      padding-top: 200px;
  }
  @media (max-width: 600px) {
      padding: 0 15px;
      padding-top: 200px;
      overflow-x: hidden;
  }
`;
const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 100px;
    @media (max-width: 600px) {
        flex-wrap: wrap;
        overflow: hidden;
    }
`;

const Col = styled.div`
    width: 50%;
    padding-right: ${props => props.left && '20px'};
    padding-left: ${props => props.right && '20px'};
    @media (max-width: 600px) {
        width: 100%;
    }
`;

const H3 = styled.h3`
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
    color: #00A8E3;
    @media (max-width: 600px) {
        font-size: 20px;
        line-height: 23px;
    }
`;
const Link = styled(BaseLink)`
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 31px;
    /* identical to box height */

    text-decoration-line: underline;
    color: #0E0E0E;
    &:hover {
        cursor: pointer;
        color: #0064BA;
    }
    @media (max-width: 1200px) {
        font-size: 20px;
        line-height: 24px;
    }
`;
const H1 = styled.h1`
    font-family: Work Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 92px;
    line-height: 80px;
    /* or 87% */

    color: #0064BA;
    max-width: 50%;
    @media (max-width: 600px) {
        font-size: 44px;
        max-width: 100%;
        line-height: 52px;
    }
`;

const H3CustomRight = styled.h3`
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    /* or 58px */

    
    margin-bottom: 42px;
    color: #0064BA;
    color: ${props => props.light && '#00A8E3'};

    @media (max-width: 1200px) {
        font-size: 32px;
        line-height: 120%;
    }
    @media (max-width: 600px) {
        margin-top: 75px;
    }
`;

const FooterWrapperContainer = styled.div`
    width: 100%;
    margin-top: 100px;
    padding-top: 57px;
    padding-bottom: 78px;
`;
const FooterWrapper = styled.div`
    margin: 0 auto;
    max-width: 1620px;
    @media (max-width: 1600px) {
        max-width: 1241px;
        padding: 0 104px;
    }
    @media (max-width: 1200px) {
        padding: 0 57px;
    }
`;
const CarWrapperParent = styled.div`
    width: 400px;
    height: 247px;
    position: absolute;
    top: 241px;
    right: 120px;
    overflow: hidden;
    @media (max-width: 1200px) {
        display: none;
    }
`;
const CarWrapperMask = styled.img`
    width: 400px;
    height: 247px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
`;
const CarWrapper = styled.img`
    width: 400px;
    height: auto;
    position: relative;
    z-index: 0;
    margin-top: 50%;
    transform: translateY(-50%);
`;
import Accordium from '../../components/common/accordium';
import TagButton from '../../components/tagButton';
import HomepageFooter from '../../components/common/footer';
import FormContext from '../../global/formContext';
import { AppearText } from '../../components/common/appear';

const SinglePage = ({
    pageContext: {
        category,
        date,
        points,
        slug,
        title,
        icon,
        hero_image,
        language,
        title_kh
    },
}) => {
    const formStatus = useContext(FormContext);
    const setForm = formStatus.setFormPersonal;

    const handleClick = () => {
        setForm({
            active: true,
            title: title,
            subTitle: category === 'personal' ? 'Personal Insurance' : 'Commercial Insurance'
        })
    }

    return (
        <>
            <Page>
                {
                    hero_image && hero_image.publicURL ? <CarWrapperParent>
                        <CarWrapperMask src={MaskProduct} alt="" />
                        <CarWrapper src={hero_image.publicURL} alt={title} />
                    </CarWrapperParent> : <noscript />
                }
                {/*
                <H3>
                    <AppearText>
                        {
                            category === 'personal' ? 'Personal Insurance' : 'Commercial Insurance'
                        }
                    </AppearText>
                </H3>
                    */}
                <H1>
                    {language === 'kh' ? title_kh : title}
                </H1>
                <Accordium
                    items={points}
                    language={language}
                />
                <Row>
                    <Col>
                        <TagButton
                            large
                            image={3}
                            icon={icon}
                            boldText="Apply Now"
                            normalText="We will take care of everything."
                            onClickHandler={() => handleClick()}
                        />
                    </Col>
                    <Col>
                        <H3CustomRight light>Looking for custom solutions?</H3CustomRight>
                        <Link to={`/${language}/contact`}>
                            Get in touch.
                        </Link>
                    </Col>
                </Row>
                <RequestConsultationMobileButton nofixed />
            </Page>
            <FooterWrapperContainer>
                <FooterWrapper>
                    <HomepageFooter variant={2} />
                </FooterWrapper>
            </FooterWrapperContainer>
        </>
    );
}

export default SinglePage;
