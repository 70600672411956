import styled from 'styled-components';


export const AccordiumContainer = styled.div`
    margin-top: 30px;
    width: 100%;
`;
export const AccordiumItem = styled.div`
    padding: 0;
    padding-top: 54px;
    padding-bottom: 69px;
    width: 100%;
    border-bottom: 1px solid #0064BA;
    @media (max-width: 600px) {
        padding: 34px 0;
    }
`;

export const H4 = styled.h4`
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;


    color: #0064BA;
    user-select: none;
    &:hover {
        cursor: pointer;
    }

    @media (max-width: 1200px) {
        font-size: 28px;
        line-height: 34px;
    }
    @media (max-width: 600px) {
        font-size: 22px;
        line-height: 26px;
    }
`;

export default {
    AccordiumContainer,
    AccordiumItem,
    H4,
};
